import { message } from "../../../../common/events/Message";
import { music_my } from "../../../../common/http/HMusic";
import { upload } from "../../../../common/http/upload";
import { getUser } from "../../../../common/utils/user";
import { isPC } from "../../../../common/utils/util";
import OfficeLoginPop from "../../vue/OfficeLoginPop";
import MP3Tag from "mp3tag.js";

/**
 * 音乐上传
 */
export default {

    data() {
        return {
            isPc: isPC(),
            user: getUser(),
            _test: null,

            muiscList: [
                // {
                //     name: "你笑起来真好看",
                //     artist: "默默",
                //     size: "12M",
                //     time: "2021-03-22 16:33",
                //     statusText: "审核中...",
                //     status: 2 // 1 审核通过  2 审核中...（目前显示为上传中...） 3 审核失败（失败暂不显示）
                // },
            ]

        }
    },

    computed: {
        headUrl() {
            return this.user?.avatarUrl || "https://vod-video.jiluyike.com/image/default/389C3BDD84D0485E9BCB61F90605AEAF-6-2.png";
        },

        upCount() {
            return this.muiscList.filter(v => v.status == 1).length;
        },

        nickName() {
            return this.user?.nickname || i18n("game");
        }
    },

    created() {
        if (!this.user) {
            dialog.showOpenDialog(OfficeLoginPop);
        } else {
            this.refreshUser();
        }
        message.register("refresh_user", () => this.refreshUser(), this);
    },

    methods: {
        async refreshUser() {
            this.user = getUser();
            if (!this.user) return;
            let res = await music_my();
            this.muiscList.length = 0;
            res.data?.forEach(v => {
                let vo = {
                    name: v.title,
                    size: this.getSize(v.size),
                    artist: v.artist,
                    time: this.getTimeText(v.createTime),
                    statusText: "上传成功",
                    status: 1
                };
                this.muiscList.push(vo);
            });
        },
        onClickUp() {
            dialog.showOpenDialog(OfficeLoginPop);
        },

        async onMuiscChange(evt) {
            // console.log("===ref==", this.$refs.fileInput.files);
            let files = this.$refs.fileInput.files || [];
            for (let index = 0, len = files.length; index < len; index++) {
                let item = files[index];
                await this.upload(item);
            }
        },

        /**
         * @param {{
                lastModified: number;
                lastModifiedDate: string;
                name: string;
                size: number;
                type: string;
                webkitRelativePath: string;
            }} file 
         */
        async upload(file) {
            loading.show();
            let timeText = this.getTimeText();
            let fileFormData = new FormData();
            fileFormData.append('file', file, file.name);

            let info = await this.getMuiscInfo(file);

            let vo = {
                name: info.name,
                size: info.size,
                artist: info.artist,
                time: timeText,
                statusText: "上传中...",
                status: 2
            };
            this.muiscList.unshift(vo);

            upload.submit(fileFormData, (res) => {
                if (res.code == 0) {
                    vo.status = 1;
                    vo.statusText = "上传成功";
                } else {
                    let idx = this.muiscList.indexOf(vo);
                    if (idx != -1) this.muiscList.splice(idx, 1);
                    this.$toas(res.msg);
                }
                loading.hide();
                return;
            });
        },

        getSize(size) {
            let HEX = 1024;
            if (size < HEX) return `${size}B`;
            else if (size >= HEX && size < Math.pow(HEX, 2)) return `$${Number((size / HEX).toFixed(2))}K`;
            else if (size >= Math.pow(HEX, 2) && size < Math.pow(HEX, 3)) return `${Number((size / Math.pow(HEX, 2)).toFixed(2))}M`;
            else return `${Number((size / Math.pow(HEX, 3)).toFixed(2))}G`;
        },

        getTimeText(dateStr) {
            let date = dateStr ? new Date(dateStr) : new Date();
            let f = (t) => {
                return t < 10 ? `0${t}` : `${t}`;
            }

            let YY = date.getFullYear();
            let MM = date.getMonth() + 1;
            let DD = date.getDate();
            let hh = date.getHours();
            let mm = date.getMinutes();
            return `${YY}-${MM}-${DD} ${f(hh)}:${f(mm)}`;
        },

        getMuiscInfo(file) {
            return new Promise(async c => {
                let reader = new FileReader();
                reader.onloadend = (rd, evt) => {
                    let mp3 = new MP3Tag(reader.result);
                    mp3.read();
                    let data = mp3.tags;
                    let info = {
                        name: data?.album || file.name,
                        size: this.getSize(file.size),
                        artist: data?.artist || "未知",
                    };
                    c(info);
                }
                reader.readAsArrayBuffer(file)
            });
        }
    },

    beforeDestroy() {
        message.unregister("refresh_user", this);
    },
}