//@ts-check
export let $hashCount = 1;
/** 框架内所有对象的基类，为对象实例提供唯一的hashCode值 */
export default class HashObject {
    constructor() {
        this.$hashCode = $hashCount++;
    }

    /** 返回此对象唯一的哈希值,用于唯一确定一个对象。hashCode为大于等于1的整数。 */
    get hashCode() {
        return this.$hashCode;
    }
}