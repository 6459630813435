import { httpApi } from "../../../build/path.config";
import { getHttp } from "./http";


export const upload = {
    /**
     * 上传数据
     * @param {FormData} file 
     */
    submit(file, callBack, onProgress) {
        let xlh = getHttp("POST", `${httpApi}/api/v1/misc/music/upload`);
        xlh.timeout = 3600 * 1000 * 24;
        xlh.ontimeout = () => {
            callBack?.({ code: 504, msg: "网络超时" });
        }
        xlh.onprogress = (evt) => {
            // console.log("==progress===", evt);
            onProgress?.();
        };

        xlh.onreadystatechange = (evt) => {
            let request = evt.currentTarget;
            if (request.readyState == 4) {
                if (request.status >= 200 && request.status < 400) {
                    let data = request.responseText;
                    let obj = JSON.parse(data);
                    callBack?.(obj);
                } else {
                    console.error("http is error!!");
                    callBack?.({ code: -1, msg: "网络错误" });
                }
            }
        };

        xlh.send(file);
    }
}


