import { isPC } from "../../../../common/utils/util"
/**
 * 首页
 */
export default {

    data() {
        return {
            isPc: true,
            swiperOption: {
                observer: true,//修改swiper自己或子元素时，自动初始化swiper 
                observeParents: true, //修改swiper的父元素时，自动初始化swiper 
                autoplay: 3000,
                autoplayDisableOnInteraction: false,
                speed: 1000,
                loop: true,
                slidesPerView: 1,
                onAutoplayStop: function (swiper) {
                    if (!swiper.support.transitions) { //IE7、IE8
                        swiper.startAutoplay()
                    }
                }
            },
            beianCode: BEIANCODE,
            descList: [
                {
                    icon: require("../../../../../assets/common/office/sec5_icon1.png"),
                    text: "扶持计划",
                    desc: '让家族享有可观收入'
                },
                {
                    icon: require("../../../../../assets/common/office/sec5_icon2.png"),
                    text: "平台资源",
                    desc: '让家族声优声量节节高升'
                },
                {
                    icon: require("../../../../../assets/common/office/sec5_icon3.png"),
                    text: "管理资源",
                    desc: '让家族拥有更完善的系统'
                },
                {
                    icon: require("../../../../../assets/common/office/sec5_icon4.png"),
                    text: "声优福利",
                    desc: '新技能接单资格优先开启'
                },
                {
                    icon: require("../../../../../assets/common/office/sec5_icon5.png"),
                    text: "专属运营",
                    desc: '第一时间为家族排忧解难'
                },
            ]
        }
    },

    created() {
      
       
    },

    methods: {
        /**
         * 点击协议 1 隐私 2用户
         * @param {1|2} type 
         */
        clickAgreement(type) {
            let path = type == 1 ? "/privacyAgreement" : "/userAgreement";
            this.$router.push(path);
        },

        /**
         * 下载 1 安卓 2 iOS
         * @param {number} type 
         */
        clickDown(/*type*/) {
            this.$toast("疯狂开发中");
        }
    },


}
