//@ts-check
import Event from './Event';
import HashObject from './HashObject';
let Keys = {};
/**
 * EventDispatcher 是事件派发器类，负责进行事件的发送和侦听。
 */
export default class EentDispatcher extends HashObject {
    /**
     * 创建一个 EventDispatcher 类的实例
     * @param {object=} target 
     */
    constructor(target) {
        super();
        this.$EventDispatcher = {
            0: target || this,
            1: {},
            2: {},
            3: 0
        };

        Keys.eventTarget = 0;
        Keys.eventsMap = 1;
        Keys.captureEventsMap = 2;
        Keys.notifyLevel = 3;
        this.ONCE_EVENT_LIST = [];
    }

    /**
     * @private
     * @param {*} useCapture 
     */
    $getEventMap(useCapture) {
        let values = this.$EventDispatcher;
        let eventMap = useCapture ? values[Keys.captureEventsMap] : values[Keys.eventsMap];
        return eventMap;
    }

    /**
     * 使用 EventDispatcher 对象注册事件侦听器对象，以使侦听器能够接收事件通知
     * @param {string} type  事件的类型
     * @param {Function} listener  处理事件的侦听器函数。此函数必须接受 Event 对象作为其唯一的参数
     * @param {any} thisObject  侦听函数绑定的this对象
     * @param {boolean=} useCapture 确定侦听器是运行于捕获阶段还是运行于冒泡阶段。如果将 useCapture 设置为 true，则侦听器只在捕获阶段处理事件，而不在冒泡阶段处理事件。如果 useCapture 为 false，则侦听器只在冒泡阶段处理事件。要在两个阶段都侦听事件，请调用 on() 两次：一次将 useCapture 设置为 true，一次将 useCapture 设置为 false。
     * @param {number=} priority 事件侦听器的优先级。优先级由一个带符号的整数指定。数字越大，优先级越高。优先级为 n 的所有侦听器会在优先级为 n -1 的侦听器之前得到处理。如果两个或更多个侦听器共享相同的优先级，则按照它们的添加顺序进行处理。默认优先级为 0
     */
    addEventListener(type, listener, thisObject, useCapture, priority) {
        this.$addListener(type, listener, thisObject, useCapture, priority);
    }

    /**
     * 添加仅回调一次的事件侦听器，此方法与on()方法不同，on()方法会持续产生回调，而此方法在第一次回调时就会自动移除监听。
     * @param {string} type 事件的类型
     * @param {Function} listener  处理事件的侦听器函数。此函数必须接受 Event 对象作为其唯一的参数，并且不能返回任何结果
     * @param {any} thisObject 侦听函数绑定的this对象
     * @param {boolean} useCapture 确定侦听器是运行于捕获阶段还是运行于冒泡阶段。如果将 useCapture 设置为 true，则侦听器只在捕获阶段处理事件，而不在冒泡阶段处理事件。如果 useCapture 为 false，则侦听器只在冒泡阶段处理事件。要在两个阶段都侦听事件，请调用 once() 两次：一次将 useCapture 设置为 true，一次将 useCapture 设置为 false
     * @param {number} priority 事件侦听器的优先级。优先级由一个带符号整数指定。数字越大，优先级越高。优先级为 n 的所有侦听器会在优先级为 n -1 的侦听器之前得到处理。如果两个或更多个侦听器共享相同的优先级，则按照它们的添加顺序进行处理。默认优先级为 0
     */
    once(type, listener, thisObject, useCapture, priority) {
        this.$addListener(type, listener, thisObject, useCapture, priority, true);
    }

    $addListener(type, listener, thisObject, useCapture, priority, dispatchOnce) {
        let values = this.$EventDispatcher;
        let eventMap = useCapture ? values[Keys.captureEventsMap] : values[Keys.eventsMap];
        let list = eventMap[type];
        if (!list) {
            list = eventMap[type] = [];
        }
        else if (values[Keys.notifyLevel] !== 0) {
            eventMap[type] = list = list.concat();
        }

        this.$insertEventBin(list, type, listener, thisObject, useCapture, priority, dispatchOnce);
    }

    $insertEventBin(list, type, listener, thisObject, useCapture, priority, dispatchOnce) {
        priority = +priority | 0;
        let insertIndex = -1;
        let length = list.length;
        for (let i = 0; i < length; i++) {
            let bin = list[i];
            if (bin.listener == listener && bin.thisObject == thisObject) {
                return false;
            }
            if (insertIndex == -1 && bin.priority < priority) {
                insertIndex = i;
            }
        }
        let eventBin = {
            type,
            listener,
            thisObject,
            priority,
            target: this,
            useCapture,
            dispatchOnce: !!dispatchOnce
        };
        if (insertIndex !== -1) {
            list.splice(insertIndex, 0, eventBin);
        }
        else {
            list.push(eventBin);
        }
        return true;
    }

    /**
     * 从 EventDispatcher 对象中删除侦听器。
     * 如果没有向 EventDispatcher 对象注册任何匹配的侦听器，则对此方法的调用没有任何效果。
     * @param {string} type 事件的类型
     * @param {Function} listener 要删除的侦听器对象
     * @param {any} thisObject 侦听函数绑定的this对象
     * @param {boolean=} useCapture 指出是为捕获阶段还是为冒泡阶段注册了侦听器。如果为捕获阶段以及冒泡阶段注册了侦听器，则需要对removeEventListener() 进行两次调用才能将这两个侦听器删除：一次调用将 useCapture 设置为 true，另一次调用将 useCapture 设置为 false
     */
    removeEventListener(type, listener, thisObject, useCapture) {
        let values = this.$EventDispatcher;
        let eventMap = useCapture ? values[Keys.captureEventsMap] : values[Keys.eventsMap];
        let list = eventMap[type];
        if (!list) {
            return;
        }
        if (values[Keys.notifyLevel] !== 0) {
            eventMap[type] = list = list.concat();
        }

        this.$removeEventBin(list, listener, thisObject);

        if (list.length == 0) {
            eventMap[type] = null;
        }
    }

    $removeEventBin(list, listener, thisObject) {
        let length = list.length;
        for (let i = 0; i < length; i++) {
            let bin = list[i];
            if (bin.listener == listener && bin.thisObject == thisObject && bin.target == this) {
                list.splice(i, 1);
                return true;
            }
        }

        return false;
    }

    /**
     * 检查 EventDispatcher 对象是否为特定事件类型注册了任何侦听器。
     * 这样，您就可以确定 EventDispatcher 对象在事件流层次结构中的哪个位置改变了对事件类型的处理。
     * 要确定特定事件类型是否确实会触发事件侦听器，请使用 IEventDispatcher.willTrigger()。
     * hasEventListener()与 willTrigger() 的区别是：hasEventListener() 只检查它所属的对象，而 willTrigger() 检查整个事件流以查找由 type 参数指定的事件。
     * @param {string} type 事件的类型。
     */
    hasEventListener(type) {
        let values = this.$EventDispatcher;
        return !!(values[Keys.eventsMap][type] || values[Keys.captureEventsMap][type]);
    }

    /**
     * 检查是否用此 EventDispatcher 对象或其任何始祖为指定事件类型注册了事件侦听器。
     * 将指定类型的事件调度给此EventDispatcher 对象或其任一后代时，如果在事件流的任何阶段触发了事件侦听器，则此方法返回 true。
     * hasEventListener() 与 willTrigger() 方法的区别是：hasEventListener() 只检查它所属的对象，而 willTrigger() 方法检查整个事件流以查找由 type 参数指定的事件。
     * @param {string} type 事件类型
     */
    willTrigger(type) {
        return this.hasEventListener(type);
    }

    /**
     * 将事件分派到事件流中。事件目标是对其调用 dispatchEvent() 方法的 EventDispatcher 对象。
     * @param {Event} event 调度到事件流中的 Event 对象
     */
    dispatchEvent(event) {
        event.$currentTarget = this.$EventDispatcher[Keys.eventTarget];
        event.setTarget(event.$currentTarget);
        return this.$notifyListener(event, false);
    }

    $notifyListener(event, capturePhase) {
        let values = this.$EventDispatcher;
        let eventMap = capturePhase ? values[Keys.captureEventsMap] : values[Keys.eventsMap];
        let list = eventMap[event.$type];
        if (!list) {
            return true;
        }
        let length = list.length;
        if (length == 0) {
            return true;
        }
        let onceList = this.ONCE_EVENT_LIST;
        //做个标记，防止外部修改原始数组导致遍历错误。这里不直接调用list.concat()因为dispatch()方法调用通常比on()等方法频繁。
        values[Keys.notifyLevel]++;
        for (let i = 0; i < length; i++) {
            let eventBin = list[i];
            eventBin.listener.call(eventBin.thisObject, event.data);
            if (eventBin.dispatchOnce) {
                onceList.push(eventBin);
            }
            if (event.$isPropagationImmediateStopped) {
                break;
            }
        }
        values[Keys.notifyLevel]--;
        while (onceList.length) {
            let eventBin = onceList.pop();
            eventBin.target.removeEventListener(eventBin.type, eventBin.listener, eventBin.thisObject, eventBin.useCapture);
        }
        return !event.$isDefaultPrevented;
    }

    /**
     * 派发一个指定参数的事件。
     * @param {string} type 事件类型
     * @param {boolean=} bubbles 确定 Event 对象是否参与事件流的冒泡阶段。默认值为 false
     * @param {any=} data 事件data
     * @param {boolean=} cancelable  确定是否可以取消 Event 对象。默认值为 false
     */
    dispatchEventWith(type, bubbles, data, cancelable) {
        if (bubbles || this.hasEventListener(type)) {
            let event = Event.create(Event, type, bubbles, cancelable);
            event.data = data;
            let result = this.dispatchEvent(event);
            Event.release(event);
            return result;
        }
        return true;
    }
}