//@ts-check
import HashObject from "./HashObject";

/**
 * 创建一个作为参数传递给事件侦听器的 Event 对象
 */
export default class Event extends HashObject {
    constructor(type, data, bubbles, cancelable) {
        super();
        this.$type = type;
        this.$bubbles = !!bubbles;
        this.$cancelable = !!cancelable;
        this.data = data;
        this.$eventPhase = 2;
        this.$currentTarget = null;
        this.$target = null;
        this.$isDefaultPrevented = false;
        this.$isPropagationStopped = false;
        this.$isPropagationImmediateStopped = false;
    }

    /**
     * 事件的类型
     * @readonly
     */
    get type() {
        return this.$type;
    }

    /**
     * 表示事件是否为冒泡事件
     * @readonly
     */
    get bubbles() {
        return this.$bubbles;
    }

    /**
     * 表示是否可以阻止与事件相关联的行为
     * @readonly
     */
    get cancelable() {
        return this.$cancelable;
    }

    /**
     * 事件流中的当前阶段
     * @readonly
     */
    get eventPhase() {
        return this.$eventPhase;
    }

    /**
     * 当前正在使用某个事件侦听器处理 Event 对象的对象
     * @readonly
     */
    get currentTarget() {
        return this.$currentTarget;
    }

    /**
     * 事件目标
     * @readonly
     */
    get target() {
        return this.$target;
    }

    setTarget(target) {
        this.$target = target;
        return true;
    }

    /**
     * 检查是否已对事件调用 preventDefault() 方法。
     * @returns 如果已调用 preventDefault() 方法，则返回 true；否则返回 false。
     */
    isDefaultPrevented() {
        return this.$isDefaultPrevented;
    }

    /**
     * 如果可以取消事件的默认行为，则取消该行为。
     * 许多事件都有默认执行的关联行为。
     * 例如，如果用户在文本字段中键入一个字符，则默认行为就是在文本字段中显示该字符。
     * 由于可以取消 TextEvent.TEXT_INPUT 事件的默认行为，因此您可以使用 preventDefault() 方法来防止显示该字符。
     * 您可以使用 Event.cancelable 属性来检查是否可以防止与特定事件关联的默认行为。
     * 如果 Event.cancelable 的值为 true，则可以使用 preventDefault() 来取消事件；否则，preventDefault() 无效。
     */
    preventDefault() {
        if (this.$cancelable)
            this.$isDefaultPrevented = true;
    }

    /**
     * 防止对事件流中当前节点的后续节点中的所有事件侦听器进行处理。
     * 此方法不会影响当前节点 currentTarget 中的任何事件侦听器。
     * 相比之下，stopImmediatePropagation() 方法可以防止对当前节点中和后续节点中的事件侦听器进行处理。
     * 对此方法的其它调用没有任何效果。可以在事件流的任何阶段中调用此方法。
     */
    stopPropagation() {
        if (this.$bubbles)
            this.$isPropagationStopped = true;
    }

    /**
     * 防止对事件流中当前节点中和所有后续节点中的事件侦听器进行处理。
     * 此方法会立即生效，并且会影响当前节点中的事件侦听器。
     * 相比之下，在当前节点中的所有事件侦听器都完成处理之前，stopPropagation() 方法不会生效。
     */
    stopImmediatePropagation() {
        if (this.$bubbles)
            this.$isPropagationImmediateStopped = true;
    }

    clean() {
        this.data = this.$currentTarget = null;
        this.setTarget(null);
    }

    /**
     * 使用指定的 EventDispatcher 对象来抛出 Event 事件对象。抛出的对象将会缓存在对象池上，供下次循环复用。
     * @param {any} target  派发事件目标
     * @param {string} type 事件类型
     * @param {boolean} bubbles  确定 Event 对象是否参与事件流的冒泡阶段。默认值为 false
     * @param {any} data 事件data
     */
    static dispatchEvent(target, type, bubbles = false, data) {
        let event = Event.create(Event, type, bubbles);
        let props = Event._getPropertyData(Event);
        if (data != undefined) {
            props.data = data;
        }
        let result = target.dispatchEvent(event);
        Event.release(event);
        return result;
    }

    static _getPropertyData(EventClass) {
        let props = EventClass._props;
        if (!props)
            props = EventClass._props = {};
        return props;
    }

    /**
     * 从对象池中取出或创建一个新的事件实例。
     * 我们建议您尽可能使用Event.create()和Event.release() 这一对方法来创建和释放事件对象，这一对方法会将事件实例在内部缓存下来供下次循环使用，减少对象的创建次数,从而获得更高的代码运行性能。
     * 注意：若使用此方法来创建自定义事件的实例，自定义的构造函数参数列表必须跟Event类一致。
     * @param {{new (type: string, bubbles?: boolean, cancelable?: boolean): any; eventPool?: any[]}} EventClass 
     * @param {string} type 事件的类型，可以作为 Event.type 访问。
     * @param {boolean} bubbles 确定 Event 对象是否参与事件流的冒泡阶段。默认值为 false。
     * @param {boolean=} cancelable 确定是否可以取消 Event 对象。默认值为 false。
     */
    static create(EventClass, type, bubbles, cancelable) {
        let eventPool;
        let hasEventPool = EventClass.hasOwnProperty("eventPool");
        if (hasEventPool) {
            eventPool = EventClass.eventPool;
        }

        if (!eventPool) {
            eventPool = EventClass.eventPool = [];
        }
        if (eventPool.length) {
            let event = eventPool.pop();
            event.$type = type;
            event.$bubbles = !!bubbles;
            event.$cancelable = !!cancelable;
            event.$isDefaultPrevented = false;
            event.$isPropagationStopped = false;
            event.$isPropagationImmediateStopped = false;
            event.$eventPhase = 2;
            return event;
        }
        return new EventClass(type, bubbles, cancelable);
    }

    /**
     * 释放一个事件对象，并缓存到对象池。
     * 我们建议您尽可能使用Event.create()和Event.release() 
     * 这一对方法来创建和释放事件对象，这一对方法会将事件实例在内部缓存下来供下次循环使用，减少对象的创建次数,从而获得更高的代码运行性能。
     * 注意：此方法只能传入由Event.create()创建的事件实例，传入非法对象实例可能会导致报错。
     * @param {Event} event 
     */
    static release(event) {
        event.clean();
        let EventClass = Object.getPrototypeOf(event).constructor;
        EventClass.eventPool.push(event);
    }
}