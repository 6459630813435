import { message } from "../../../../common/events/Message";
import { getSmsCode, phoneLogin } from "../../../../common/http/HUser";
import { setUser } from "../../../../common/utils/user";
let curTime = 59;
let CAN_GET = true;

/**
 * 登录弹框
 */
export default {

    data() {
        return {
            codeTime: "获取验证码",
            _timer: 0,
            time: curTime,
            canGet: CAN_GET,
            smsCode: "",
            phone: ""
        }
    },

    created() {
        if (!this.canGet) {
            this.starTimer();
        }
    },

    methods: {
        closePage() {
            message.send("refresh_user");
            dialog.closeOpenDialog(this);
        },

        async getCode() {
            if (!this.canGet || !this.checkPhone()) return;

            let res = await getSmsCode(this.phone);
            if (res.code != 0) {
                return this.$toast(res.msg);
            }
            this.$toast("验证码已发送，请注意查收");
            CAN_GET = this.canGet = false;
            this.starTimer();
        },

        starTimer() {
            this.codeTime = `重新获取(${curTime})`;
            this._timer = setInterval(() => {
                curTime--
                if (curTime < 0) {
                    curTime = 59;
                    CAN_GET = this.canGet = true;
                    this.codeTime = "获取验证码";
                    clearInterval(this._timer);
                    return;
                }
                this.codeTime = `重新获取(${curTime})`;
            }, 1000);
        },

        async login() {
            if (!this.checkPhone()) return;
            if (!this.smsCode) {
                this.$toast("请输入验证码");
                return;
            }

            let res = await phoneLogin(this.phone, this.smsCode);
            if (res.code !== 0) return this.$toast(res.msg);
            setUser(res.data);
            this.closePage();
        },

        checkPhone() {
            if (!this.phone) {
                this.$toast("请输入手机号");
                return false;
            }
            return true;
        }
    },

    beforeDestroy() {
        clearInterval(this._timer);
    },

}
