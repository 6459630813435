
import EventDispatcher from './EventDispatcher';
let eventDispatcher = new EventDispatcher();

import Event from './Event';

class Message {
    /**
     * 注册事件
     * @param {string} type 
     * @param {Function} call 
     * ?@param {Object=} thisObject 
     * ?@param {any=} priority 
     */
    register(type, call, thisObject, priority) {
        eventDispatcher.addEventListener(type, call, thisObject, false, priority);
    }

    /**
     * 移除事件
     * @param {string} type 
     * @param {Function} call 
     * @param {any=} thisObject 
     */
    unregister(type, call, thisObject) {
        eventDispatcher.removeEventListener(type, call, thisObject);
    }

    /**
     * 发送事件
     * @param {string} type 
     * @param {any=} data 
     */
    send(type, data) {
        // @ts-ignore
        eventDispatcher.dispatchEvent(new Event(type, data));
    }
}

export const message = new Message();