import { goods_list, trade_payWay, trade_recharge } from "../../../../common/http/HShop";
import { getUserInfo } from "../../../../common/http/HUser";
import { isPC } from "../../../../common/utils/util"
import AccoutRecharge from "../../../accout/vue/AccoutRecharge"
/**
 * 好玩充值
 */
export default {

    data() {
        return {
            isPc: isPC(),
            userId: "",
            nameDesc: "",
            isErrId: false,
            channelList: [],
            checkState: false,
            rmbText: "0.00",
            rechargeList: [],
        }
    },

    computed: {
        /**
         * 当前选择
         * @returns {{gold:number,rmb:string;money:number;select:boolean;goodsId:number}}
         */
        curRecharge() {
            return this.rechargeList.find((v) => v.select);
        },

        /**
         * 当前充值通道
         * @returns {{select:boolean} & PayWay}
         */
        curChannel() {
            return this.channelList.find((v) => v.select);
        },
    },

    created() {

        // for (let index = 1; index < 10; index++) {

        //     this.rechargeList.push({
        //         goodsId: index,
        //         gold: index * 10,
        //         rmb: `￥${index.toFixed(2)}`,
        //         money: index,
        //         select: false
        //     });
        // }

        // for (let index = 1; index < 3; index++) {
        //     this.channelList.push({
        //         isDefault: index,
        //         payTypeCode: index,
        //         payTypeName: "",
        //         payWayCode: index,
        //         payWayName: "微信",
        //         select: index == 1
        //     });
        // }
        this.refreshPage();
    },

    methods: {
        /**
         * 勾选协议
         */
        clickCheckBox() {
            this.checkState = !this.checkState;
        },

        /**
         * 选择套餐
         */
        onSelect(item, idx) {
            if (this.curRecharge) {
                if (this.curRecharge.goodsId == item.goodsId) return;
                this.curRecharge.select = false;
            }

            this.rechargeList[idx].select = true;
            this.rmbText = (item.money / 100).toFixed(2);
        },
        /**
         * 充值渠道选择
         * @param {PayWay} channel
         * @param {number} index
         */
        onSelectChannel(channel, index) {
            if (this.curChannel) {
                if (this.curChannel.payWayCode === channel.payWayCode) return;
                this.curChannel.select = false;
            }
            this.channelList[index].select = true;
        },

        /**
         * 查看协议
         */
        clickAgreement() { },

        /**
         * 充值
         */
        async goRecharge() {
            if (!this.userId) {
                this.$toast("请输入用户ID");
                this.onUserIdIsNull(true);
                return;
            }

            if (!this.curRecharge) {
                this.$toast("请选择充值套餐");
                return;
            }

            if (!this.checkState) {
                this.$toast("请先阅读充值协议");
                return;
            }

            if (!this.curChannel) {
                this.$toast("请选择支付方式");
                return;
            }

            let res = await trade_recharge(this.curRecharge.goodsId, this.curChannel.payWayCode, this.userId)
            if (res.code == 0) {


            } else {
                this.$toast(res.msg);
            }
        },

        async onSearch() {
            if (!this.userId) {
                this.onUserIdIsNull(true);
                return;
            }

            if (this.userId.length < 4) {
                this.nameDesc = "请输入正确的用户ID";
                this.isErrId = true;
                return;
            }

            let res = await getUserInfo(this.userId);
            if (res.code == 0) {
                this.nameDesc = `昵称：${res.data.nickName}`;
                this.isErrId = false;
            } else {
                this.nameDesc = res.msg;
                this.isErrId = true;
            }
        },

        onUserIdIsNull(isNull) {
            if (isNull) {
                this.nameDesc = "充值ID不能为空";
                this.isErrId = true;
            } else {
                this.nameDesc = "";
                this.isErrId = false;
            }
        },

        async refreshPage() {
            let goods_list_res = await goods_list(2);
            if (goods_list_res.code == 0) {
                this.rechargeList.length = 0;
                goods_list_res.data.forEach(v => {
                    let recharge = { goodsId: v.goodsId, select: false };
                    recharge.gold = v.goodsName;
                    recharge.rmb = `￥${Number((v.price / 100).toFixed(2))}`;
                    recharge.money = v.price;
                    this.rechargeList.push(recharge);
                });

            }

            let trade_payWay_res = await trade_payWay();
            if (trade_payWay_res.code == 0) {
                this.channelList.length = 0;
                trade_payWay_res.data.forEach(v => {
                    v.select = v.isDefault == 1;
                    this.channelList.push(v);
                });
            }
        },

    },

    components: {
        AccoutRecharge
    }
}
